import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CreateAdminClinicForm from '@src/components/forms/createAdminClinic.form';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}/admin`;

export default function CreateAdminClinic() {
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const state = useLocation();
  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const [getClinic, setGetClinic] = useState([]);
  const [createAdmin, setCreateAdmin] = React.useState({
    admin_category: '',
    name: '',
    email: '',
    phone_number: '',
    password: '',
    clinicId: '',
    companyId: '',
    isChannelNotification: false,
    access_role: '',
  });

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/organizations/${orgId}/clinics`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetClinic(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, orgId]);

  function createAdminClinic() {
    const formData = {
      admin_category: createAdmin?.admin_category,
      name: createAdmin?.name,
      email: createAdmin?.email,
      phone_number: createAdmin?.phone_number,
      password: createAdmin?.password,
      clinicId: createAdmin?.clinicId,
      companyId: orgId,
      isChannelNotification: createAdmin.isChannelNotification,
      access_role: createAdmin?.access_role,
    };

    axios
      .post(`${url}/users/create-admin`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account created successfully:', response.data);
        setCreateAdmin({ ...createAdmin });
        toast.success('Account created successfully', {
          autoClose: 2000,
          position: 'top-center',
        });
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error(`${error.response.data.message}`, {
          autoClose: 2000,
          position: 'top-center',
        });
      });
    // console.log(formData);
  }

  const handleOpenModal = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    // navigate('/organisation');
  };

  const handleSaveButtonClick = () => {
    if (!createAdmin?.name) {
      toast.error('Name is required.');
      return;
    }
    if (!createAdmin?.email) {
      toast.error('Email is required.');
      return;
    }
    if (!createAdmin?.password) {
      toast.error('Password is required.');
      return;
    }
    setTimeout(() => {
      createAdminClinic();
      // navigate(state?.prevLocation || -1);
      handleOpenModal();
      // toast.success('Admin has been created');
    }, 1000);
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>

      <Box
        sx={{
          width: '75%',
          fontFamily: 'Poppins',
          display: 'flex',
          justifyContent: 'center',
        }}
        className='py-6'
      >
        <Box
          className='rounded-xl'
          sx={{
            height: 'auto',
            width: 'auto',
            backgroundColor: 'white',
            margin: 1,
          }}
        >
          <div className='font-poppins py-8 px-4 text-lg font-bold'>
            Sign-up Information
          </div>
          <CreateAdminClinicForm
            createAdmin={createAdmin}
            setCreateAdmin={setCreateAdmin}
            getClinic={getClinic}
          />
          <div className='flex justify-center px-6 py-4'>
            <Button
              sx={{
                textTransform: 'none',
                width: '200px',
                height: '48px',
              }}
              className='text-md rounded-md bg-cyan-600 font-bold text-white'
              variant='contained'
              onClick={handleSaveButtonClick}
            >
              Sign up
            </Button>
          </div>
          <br />
        </Box>
      </Box>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The Admin account has been created successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
