/* eslint-disable prettier/prettier */

/* eslint-disable no-constant-condition */

/* eslint-disable jsx-a11y/anchor-is-valid */
import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ContactInformation from '@src/components/forms/contactInformation';
import PersonalInformation from '@src/components/forms/personalInformation.form';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';
import dayjs from 'dayjs';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function AppUserVerified() {
  const { auth } = useAuth();
  const { userId } = useParams();
  const [profile, setProfile] = useState({ phone_number: '' });
  const [email, setEmail] = useState({ new_email: '', user_id: '' });

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/admin/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setProfile(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userId]);

  const [getUser, setGetUser] = useState({
    name: '',
    gender: '',
    nationality: '',
    nric_fin: '',
    date: '',
    residential_status: '',
    address: '',
    referal: '',
  });

  useEffect(() => {
    if (auth.idToken) {
      axios
        .get(`${url}/personal-informations/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetUser(response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handlePutProfile = () => {
    const formData = {
      name: getUser.name,
      gender: getUser.gender,
      nationality: getUser.nationality,
      nric_fin: getUser.nric_fin,
      date_of_birth:
        getUser?.date ||
        dayjs(getUser?.['Date of Birth'], 'D MMMM YYYY').format('YYYY-MM-DD'),
      residential_status: getUser.residential_status,
      address: getUser.address,
      referal: getUser.referal,
    };

    axios
      .post(`${url}/user/personal-information/${userId}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account updated successfully:', response.data);
        setGetUser({ ...getUser });
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error('Failed to update profile', {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  const updateUser = (formData) => {
    axios
      .put(`${url}/admin/users/${userId}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account updated successfully:', response.data);
        setProfile({ ...profile });
      })
      .catch((error) => {
        console.log('Error:', error.message);
      });
  };

  const updateEmail = (formData) => {
    axios
      .post(`${url}/auth/change-email`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account updated successfully:', response.data);
        setProfile({ ...profile });
        toast.success('Profile updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log('Error:', error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  const handlePutEmail = () => {
    updateEmail({
      new_email: profile.email,
      user_id: userId,
    });
  };

  const handlePutNumber = () => {
    updateUser({
      phone_number: profile.phone_number,
    });
  };

  const handleSuspend = () => {
    updateUser({ status: 'suspended' });
    toast.success('User updated successfully', {
      position: 'top-center',
      autoClose: '1000',
    });
  };

  const handleUnsuspend = () => {
    updateUser({ status: 'verified' });
    toast.success('User updated successfully', {
      position: 'top-center',
      autoClose: '1000',
    });
  };

  const handleValidateProfile = async () => {
    if (!getUser?.Name) {
      toast.error('Name is required.');
      return;
    }
    if (!getUser?.NRIC) {
      toast.error('NRIC is required.');
      return;
    }
    if (!getUser?.Nationality) {
      toast.error('Nationality is required.');
      return;
    }
    if (!getUser?.Gender) {
      toast.error('Gender is required.');
      return;
    }
    if (!getUser?.['residential Status']) {
      toast.error('Residential status is required.');
      return;
    }
    if (!getUser?.Address) {
      toast.error('Address is required.');
      return;
    }
    setTimeout(() => {
      handlePutProfile();
    }, 2000);
  };

  function handleUnverifyUser() {
    axios
      .post(
        `${url}/admin/users/set-unverified/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        },
      )
      .then((response) => {
        setProfile({ ...profile });
        console.log('User unverify successfully:', response.data);
        toast.success('User unverified successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error('Failed to unverify user', {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  }

  const [openUnverifyModal, setOpenUnverifyModal] = React.useState(false);
  const handleUnverify = () => {
    setOpenUnverifyModal(true);
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      <div className='grid grid-cols-2 py-4'>
        <div className='col-end-1'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Personal Information
            </div>
            <PersonalInformation getUser={getUser} setGetUser={setGetUser} />
          </Box>
        </div>
        <div className='col-auto'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <ContactInformation
              email={email}
              setEmail={setEmail}
              profile={profile}
              setProfile={setProfile}
            />
            <br />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: '175px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='flex justify-center py-16 px-6'>
              <Button
                sx={{
                  textTransform: 'none',
                  width: '130px',
                  height: '40px',
                }}
                className='rounded-md border border-red-500 bg-red-500 px-6 font-bold text-white'
                variant='outlined'
                onClick={() => {
                  handleUnverify();
                }}

                // eslint-disable-next-line react/jsx-no-bind
              >
                Unverify
              </Button>
              &nbsp; &nbsp; &nbsp; &nbsp;
              {profile?.status && (
                <Button
                  sx={{
                    textTransform: 'none',
                    width: '130px',
                    height: '40px',
                  }}
                  className='round-md bg-purple-500 text-sm font-bold text-white'
                  variant='contained'
                  onClick={() => {
                    if (profile.status === 'verified') {
                      handleSuspend();
                    } else if (profile.status === 'suspended' || 'deleted') {
                      handleUnsuspend();
                    }
                  }}
                >
                  {profile.status === 'verified'
                    ? 'Suspend'
                    : profile.status === 'suspended'
                    ? 'Unsuspend'
                    : profile.status === 'deleted'
                    ? 'Undelete'
                    : 'Unknown Action'}
                </Button>
              )}
              &nbsp; &nbsp; &nbsp; &nbsp;
              <Button
                sx={{
                  textTransform: 'none',
                  width: '130px',
                  height: '40px',
                }}
                className='rounded-md border border-cyan-500 bg-white px-6 font-bold text-cyan-600'
                variant='outlined'
                onClick={() => {
                  handleValidateProfile();
                  handlePutEmail();
                  handlePutNumber();
                }}

                // eslint-disable-next-line react/jsx-no-bind
              >
                Update
              </Button>
            </div>
          </Box>
        </div>
      </div>

      <Dialog
        open={openUnverifyModal}
        onClose={() => setOpenUnverifyModal(false)}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <p className='font-poppins'>
            Are you sure you want to unverify this user?
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            fontFamily='poppins'
            onClick={() => setOpenUnverifyModal(false)}
          >
            Cancel
          </Button>
          <Button
            color='error'
            fontFamily='poppins'
            onClick={() => {
              handleUnverifyUser();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
