import '@fontsource/poppins';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}/admin/users/create-doctor`;

export default function CreateAccountDoctor() {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const state = useLocation();
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const [createDoctor, setCreateDoctor] = React.useState({
    name: '',
    email: '',
    password: '',
    phone_number: '',
  });

  function createNewDoctor() {
    const formData = {
      name: createDoctor.name,
      email: createDoctor.email,
      password: createDoctor.password,
      phone_number: createDoctor.phone_number,
    };

    axios
      .post(`${url}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account created successfully:', response.data);
        setCreateDoctor({ ...createDoctor });
        setOpen(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 2000,
          position: 'top-center',
        });
      });
    // console.log(formData);
  }

  const handleOpenModal = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    navigate('/');
  };

  const handleSaveButtonClick = () => {
    if (!createDoctor?.name) {
      toast.error('Name is required.');
      return;
    }

    if (!createDoctor?.phone_number) {
      toast.error('Phone number is required.');
      return;
    }
    if (!createDoctor?.email) {
      toast.error('Email is required.');
      return;
    }
    if (!createDoctor?.password) {
      toast.error('Password is required.');
      return;
    }
    setTimeout(() => {
      createNewDoctor();
      handleOpenModal();
      // toast.success('Admin has been created');
    }, 1000);
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [previousEmail, setPreviousEmail] = useState('');

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setCreateDoctor({
      ...createDoctor,
      email: newEmail,
    });

    if (newEmail === previousEmail) {
      toast.error('Email cannot be the same as the previous one', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <Box paddingLeft={35} py={4}>
        <Box
          width='500px'
          sx={{ backgroundColor: 'white', borderRadius: 4 }}
          p={3}
        >
          <Typography
            fontWeight={700}
            fontFamily='Poppins'
            fontSize={20}
            mt={1}
          >
            Sign-up Information
          </Typography>
          <br />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box flex={1} mr={1}>
              <Typography fontFamily='Poppins' mt={3}>
                Name*
              </Typography>
              <TextField
                fullWidth
                size='medium'
                variant='outlined'
                placeholder='Enter name'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '6px',
                  },
                  mt: '5px',
                }}
                value={createDoctor?.name || ''}
                onChange={(e) =>
                  setCreateDoctor({
                    ...createDoctor,
                    name: e.target.value,
                  })
                }
              />
              <br />
              <br />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box flex={1} mr={1}>
              <Typography fontFamily='Poppins'>Email*</Typography>
              <TextField
                fullWidth
                size='medium'
                variant='outlined'
                placeholder='Enter email'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '6px',
                  },
                  mt: 1,
                }}
                value={createDoctor?.email}
                onChange={handleEmailChange}
              />
              <br />
              <br />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box flex={1} mr={1}>
              <Typography fontFamily='Poppins'>Phone number*</Typography>
              <TextField
                fullWidth
                size='medium'
                variant='outlined'
                placeholder='Enter phone number. e.g. +65'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '6px',
                  },
                  mt: 1,
                }}
                value={createDoctor?.phone_number}
                onChange={(e) =>
                  setCreateDoctor({
                    ...createDoctor,
                    phone_number: e.target.value,
                  })
                }
              />
              <br />
              <br />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box>
              <Typography fontFamily='Poppins'>
                Password for account*
              </Typography>
              <FormControl className='py-2'>
                <OutlinedInput
                  fullWidth
                  size='medium'
                  sx={{ width: '490px' }}
                  placeholder='Enter password'
                  id='password'
                  type={showPassword ? 'text' : 'password'}
                  value={createDoctor?.password || ''}
                  onChange={(e) =>
                    setCreateDoctor({
                      ...createDoctor,
                      password: e.target.value,
                    })
                  }
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </Box>
          <Box paddingLeft={18} py={4}>
            <Button
              sx={{
                textTransform: 'none',
                width: '200px',
                height: '48px',
              }}
              className='text-md rounded-md bg-cyan-600 font-bold text-white'
              variant='contained'
              onClick={handleSaveButtonClick}
            >
              Sign up
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The Doctor account has been created successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
