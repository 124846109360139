import { useAuth } from '@contexts/authContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import ButtonAction from '@src/components/buttons/action.button';
import AddNotifChannel from '@src/components/modals/addNotificationChannel';
import EditNotificationChannel from '@src/components/modals/editNotifChannel';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/clinic/profile/settings/notification`;

export default function ClinicSetting() {
  const { auth } = useAuth();
  const { clinicId } = useParams();
  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState();

  const [notif, setNotif] = React.useState([]);

  const fetchData = () => {
    setLoading(true);
    if (auth.idToken) {
      axios
        .get(`${url}?clinic_id=${clinicId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setNotif(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, deleteStatus]);

  const handleCheck = (index) => {
    const formData = {
      channel_is_active: !notif[index].channel_is_active,
    };

    axios
      .put(`${url}/${notif[index]._id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setNotif((prevNotif) => {
          const updatedNotif = [...prevNotif];
          updatedNotif[index].channel_is_active =
            !prevNotif[index].channel_is_active;
          return updatedNotif;
        });
        fetchData();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleVerify = (index) => {
    const formData = {
      channel_is_verified: !notif[index].channel_is_verified,
    };

    axios
      .put(`${url}/${notif[index]._id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setNotif({ ...notif });
        fetchData();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${url}/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
          'My-Custom-Header': 'foobar',
        },
      });
      console.log(response);
      setDeleteStatus('Delete successful');
      toast.success(`${response.data.message}`, {
        autoClose: 2000,
        position: 'top-center',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const showModal = (itemId) => {
    setOpenModal(itemId);
  };

  const [addModal, setAddModal] = useState(false);
  const showAddModal = () => {
    setAddModal(true);
  };

  return (
    <>
      <Box
        sx={{ width: '100%', height: '60px' }}
        className='flex w-2/3 justify-end px-16'
      >
        <ButtonAction
          onNavigate={() => {
            showAddModal();
          }}
          label='Add New Item'
        />
      </Box>
      <Box sx={{ width: '100%' }} className='w-2/3'>
        {loading ? (
          <div className='font-poppins py-6 px-10 text-gray-500'>
            Loading...
          </div>
        ) : (
          <div>
            {Array.isArray(notif) && notif.length > 0 ? (
              notif.map((data, index) => (
                <Paper
                  key={data?.the_id}
                  sx={{ borderRadius: '12px', padding: 4, margin: 2 }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      backgroundColor: 'white',
                    }}
                  >
                    <Box sx={{ display: 'flex', mr: 2, mb: 2 }}>
                      <Box sx={{ width: '240px' }}>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            fontWeight: 500,
                            lineHeight: '21px',
                            fontFamily: 'Poppins',
                            mt: 1,
                          }}
                        >
                          {data?.channel_type}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            paddingTop: 1,
                            fontWeight: 400,
                            lineHeight: '24px',
                            color: 'gray',
                            fontFamily: 'Poppins',
                            mt: 1,
                          }}
                        >
                          {data?.channel_value}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ mr: 6, mb: 2, width: '110px' }}>
                      <Typography
                        variant='subtitle1'
                        sx={{
                          fontWeight: 500,
                          lineHeight: '21px',
                          fontFamily: 'Poppins',
                          mt: 1,
                        }}
                      >
                        Notes
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        sx={{
                          paddingTop: 1,
                          fontWeight: 400,
                          lineHeight: '24px',
                          color: 'gray',
                          fontFamily: 'Poppins',
                          mt: 1,
                        }}
                      >
                        {data?.channel_note || '-'}
                      </Typography>
                    </Box>
                    <Box sx={{ width: 'auto', mr: 3, mb: 2 }}>
                      <Typography
                        variant='subtitle1'
                        sx={{
                          fontWeight: 500,
                          lineHeight: '21px',
                          fontFamily: 'Poppins',
                          mt: 1,
                        }}
                      >
                        Active
                      </Typography>
                      <Switch
                        sx={{ mt: 1 }}
                        checked={data.channel_is_active}
                        onChange={() => handleCheck(index)}
                        name={`checked-${index}`}
                        inputProps={{
                          'aria-label': `primary checkbox ${index}`,
                        }}
                      />
                    </Box>
                    <Box sx={{ mr: 2, mb: 2 }}>
                      <Typography
                        variant='subtitle1'
                        sx={{
                          fontWeight: 500,
                          lineHeight: '21px',
                          fontFamily: 'Poppins',
                          mt: 1,
                        }}
                      >
                        Verify
                      </Typography>

                      <Switch
                        sx={{ mt: 1 }}
                        checked={data.channel_is_verified}
                        onChange={() => handleVerify(index)}
                        name={`checked-${index}`}
                        inputProps={{
                          'aria-label': `primary checkbox ${index}`,
                        }}
                      />
                    </Box>
                    <Box sx={{ mr: 2, mb: 2, mt: 5, ml: 3 }}>
                      <Button
                        sx={{ textTransform: 'none' }}
                        variant='outlined'
                        color='secondary'
                        className='rounded-md'
                        onClick={() => showModal(data?._id)}
                      >
                        Change
                      </Button>
                      &nbsp; &nbsp; &nbsp;
                      <Button
                        sx={{ textTransform: 'none' }}
                        variant='outlined'
                        color='error'
                        className='rounded-md'
                        onClick={() => showDeleteConfirmationDialog(data._id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>

                  {showDeleteConfirmation && (
                    <Dialog
                      open={showDeleteConfirmation}
                      onClose={handleConfirmationDialogClose}
                    >
                      <DialogTitle>Confirm Delete</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Are you sure you want to delete this item?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleConfirmationDialogClose}
                          color='primary'
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleConfirmationDialogConfirm}
                          color='error'
                        >
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                </Paper>
              ))
            ) : (
              <div className='font-poppins text-md px-6 py-8 text-gray-600'>
                no data
              </div>
            )}
          </div>
        )}

        <EditNotificationChannel
          showModal={showModal}
          openModal={openModal}
          fetchData={fetchData}
          setOpenModal={setOpenModal}
        />

        <AddNotifChannel
          addModal={addModal}
          fetchData={fetchData}
          setAddModal={setAddModal}
        />
      </Box>
    </>
  );
}
