import '@fontsource/poppins';

import React from 'react';

export default function ContactInformation({
  email,
  setEmail,
  profile,
  setProfile,
}) {
  return (
    <>
      <div className='font-poppins py-4 px-6 text-lg font-bold'>
        Contact Information
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <label className='font-poppins  block text-sm font-medium leading-6 text-gray-600'>
            Email
          </label>
          <div className='mt-2'>
            <input
              placeholder='e.g. john@company.com'
              type='text'
              className='font-poppins block w-full rounded-md border-0 px-2 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600  sm:leading-6'
              style={{ height: '38px', fontSize: 16, paddingRight: 2 }}
              value={profile?.email}
              onChange={(e) =>
                setProfile({ ...profile, email: e.target.value })
              }
            />
          </div>
        </div>

        <div className='sm:col-span-3'>
          <label className='font-poppins  block text-sm font-medium leading-6 text-gray-600'>
            Mobile No.
          </label>
          <div className='mt-2'>
            <input
              placeholder='e.g. +65'
              type='text'
              className='font-poppins block w-full rounded-md border-0 px-2 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600  sm:leading-6'
              style={{ height: '38px', fontSize: 16, paddingRight: 3 }}
              value={profile?.phone_number}
              onChange={(e) =>
                setProfile({ ...profile, phone_number: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
