import '@fontsource/poppins';
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';
import dayjs from 'dayjs';

import React, { useState } from 'react';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/lookup/master-data`;

export default function PersonalInformation({ getUser, setGetUser }) {
  const [open, setOpen] = React.useState(false);
  const { auth } = useAuth();
  const [nationality, setNationality] = React.useState([]);
  const [residen, setResiden] = React.useState([]);
  const getResident = () => {
    axios
      .get(`${url}/residential-status`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setResiden(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getNationality = () => {
    axios
      .get(`${url}/country`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setNationality(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getResident();
    getNationality();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box flex={1} ml={2}>
          <Typography fontFamily='Poppins' mt={1}>
            Name as per ID*
          </Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            placeholder='Enter name'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: '5px',
              width: '230px',
            }}
            value={getUser?.Name}
            onChange={(e) =>
              setGetUser({
                ...getUser,
                name: e.target.value,
                Name: e.target.value,
              })
            }
          />
          <br />
          <br />
        </Box>
        <Box flex={1} mr={2} ml={2} mt={1}>
          <Typography fontFamily='Poppins'>Nationality*</Typography>
          <Box mt={0.5}>
            <select
              id='resident'
              value={getUser?.Nationality}
              onChange={(e) =>
                setGetUser({
                  ...getUser,
                  nationality: e.target.value,
                  Nationality: e.target.value,
                })
              }
              className='font-poppins block w-full rounded-md border-0 px-2 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600  sm:leading-6'
              style={{ height: '58px', width: '200px', fontSize: 16 }}
            >
              <option value=''>Select one</option>
              {nationality.map((nat) => (
                <option value={nat.label}>{nat?.label}</option>
              ))}
            </select>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box flex={1} ml={2}>
          <Typography fontFamily='Poppins'>NRIC*</Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            placeholder='Enter NRIC'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: 1,
              width: '230px',
            }}
            value={getUser?.NRIC}
            onChange={(e) =>
              setGetUser({
                ...getUser,
                nric_fin: e.target.value,
                NRIC: e.target.value,
              })
            }
          />
          <br />
          <br />
        </Box>

        <Box flex={1} mr={2} ml={2}>
          <Typography fontFamily='Poppins'>Gender*</Typography>
          <Box mt={0.6}>
            <select
              id='gender'
              value={getUser?.Gender}
              onChange={(e) =>
                setGetUser({
                  ...getUser,
                  gender: e.target.value,
                  Gender: e.target.value,
                })
              }
              className='font-poppins block w-full rounded-md border-0 px-2 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600  sm:leading-6'
              style={{ height: '58px', width: '200px', fontSize: 16 }}
            >
              <option value=''>Select one</option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </select>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box flex={1} ml={2}>
          <Typography fontFamily='Poppins'>Date of Birth*</Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format='D MMMM YYYY'
              value={
                getUser?.['Date of Birth']
                  ? dayjs(getUser?.['Date of Birth'], 'D MMMM YYYY')
                  : null
              }
              onChange={(date) => {
                setGetUser({
                  ...getUser,
                  date: date ? date.format('YYYY-MM-DD') : '',
                });
                setOpen(false);
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                },
                mt: 1,
                width: '230px',
              }}
            />
          </LocalizationProvider>
        </Box>

        <Box flex={1} mr={2} ml={2}>
          <Typography fontFamily='Poppins'>Residential Status*</Typography>
          <Box mt={0.9}>
            <select
              id='resident'
              value={getUser?.['residential Status']}
              onChange={(e) =>
                setGetUser({
                  ...getUser,
                  residential_status: e.target.value,
                  'residential Status': e.target.value,
                })
              }
              className='font-poppins block w-full rounded-md border-0 px-2 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600  sm:leading-6'
              style={{ height: '58px', width: '200px', fontSize: 16 }}
            >
              <option value=''>Select one</option>
              {residen.map((res) => (
                <option value={res.label}>{res?.label}</option>
              ))}
            </select>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box flex={1} mr={2} ml={2} mt={3}>
          <Typography fontFamily='Poppins'>Residential Address*</Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            multiline
            rows={3}
            placeholder='Enter address'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: 1,
            }}
            value={getUser?.Address}
            onChange={(e) =>
              setGetUser({
                ...getUser,
                Address: e.target.value,
                address: e.target.value,
              })
            }
          />
          <br />
          <br />
        </Box>
      </Box>
    </Box>
  );
}
