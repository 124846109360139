/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/order
import { fileDownloader } from '@api/FileDownloader';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Container from '@src/components/commons/containerPage.common';
import { useAuth } from '@src/contexts/authContext';
import { downloadFile } from '@utils';
import axios from 'axios';
import dayjs from 'dayjs';

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const urls = `${process.env.REACT_APP_API_URL}/admin/clinics`;

const roles = [
  { value: 'doctor', label: 'Doctor' },
  { value: 'clinical assistant', label: 'Clinical Assistant' },
  { value: 'nurse', label: 'Nurse' },
];

const options = [
  { value: 'empty_slots', label: 'Empty Slots' },
  { value: 'filled_slots', label: 'Filled Slots' },
  { value: 'missed_slots', label: 'Missed Slots' },
  { value: 'past_slots', label: 'Past Slots' },
  { value: 'pending_slots', label: 'Pending Slots' },
];

const downloadFileList = (getClinic) => [
  {
    url: '/admin/users/download-doctors-list',
    name: 'loc-doctor-list',
    label: 'Download Doctor List',
  },
  {
    url: '/admin/users/download-cla-list',
    name: 'cla-clinical-assistant-list',
    label: 'Download Clinical Assistant List',
  },
  {
    url: '/admin/users/download-nurses-list',
    name: 'nurse-list',
    label: 'Download Nurse List',
  },
  {
    url: '/download-pending-users',
    name: 'unverified-user-list',
    label: 'Download Unverified User List',
  },
  {
    url: '/admin/download/confirmed',
    name: 'confirmed-list',
    label: 'Download Confirmed Slots List',
  },
  {
    url: '/admin/download/download-cancelled-slots',
    name: 'cancelled-slots-list',
    label: 'Download Cancelled Slots List',
  },
  {
    url: '/admin/download/download-deleted-slots',
    name: 'deleted-slots-list',
    label: 'Download Deleted Slots List',
  },
  {
    url: '/jobs/download/empty-slots',
    name: 'empty-slots-list',
    label: 'Download Empty Slots List',
    config: {
      params: { doc_type: 'xlsx' },
    },
  },
  {
    url: '/admin/download/organizations',
    name: 'organizations-list',
    label: 'Download Organisations List',
  },
  {
    url: '/admin/jobs/download/clinic/slots',
    name: 'all-slot-list',
    label: 'Download All Slots',
    config: {
      params: {
        profession: roles?.value,
        doc_type: 'xlsx',
        clinic_id: getClinic?.clinic_id,
        slot_type: options?.value,
        from: getClinic?.from,
        to: getClinic?.to,
      },
    },
  },
];

function DownloadListPage() {
  const { auth } = useAuth();
  const path = useLocation().pathname;

  const [getClinic, setGetClinic] = useState([
    {
      clinic_id: '',
      profession: '',
      slot_type: '',
      from: dayjs().format('YYYY-MM-DD'),
      to: dayjs().add(30, 'day').format('YYYY-MM-DD'),
    },
  ]);

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${urls}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetClinic(response.data || []);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const listData = React.useMemo(() => {
    if (auth && auth.user) {
      const filterList = (item) =>
        // allow list other than these
        item.name !== 'organizations-list' ||
        item.name !== 'cancelled-slots-list' ||
        item.name !== 'deleted-slots-list' ||
        item.name !== 'empty-slots-list' ||
        // check userId for these list
        ((item.name === 'organizations-list' ||
          item.name === 'cancelled-slots-list' ||
          item.name === 'deleted-slots-list' ||
          item.name === 'empty-slots-list') &&
          auth &&
          auth.user &&
          // for skincaresg@production && locum65@staging
          (auth.user._id === '642e3b44188500986b4ea473' ||
            auth.user._id === '637c816e4b3a098e6f38065e'));

      return downloadFileList(getClinic).filter(filterList);
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, getClinic]);

  const [loading, setLoading] = React.useState(false);

  const handleDownload = (url, fileName, config) => async () => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [fileName]: true,
    }));

    try {
      const result = await fileDownloader(auth.idToken, url, config);
      downloadFile(result, fileName);
      toast.success('Download successful', {
        position: 'top-center',
        autoClose: 2000,
      });
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error(`${error.message}`, {
        position: 'top-center',
        autoClose: 2000,
      });
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [fileName]: false,
      }));
    }
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleButtonClick = (item) => {
    if (item.name === 'all-slot-list') {
      setOpenModal(true);
    } else {
      const { url, name, config } = item;
      handleDownload(url, name, config)();
    }
  };

  return (
    <>
      <Container pathUrl={path}>
        <div style={{ padding: '16px' }}>
          {listData.map((item) => (
            <Button
              className='font-poppins font-bold'
              type='button'
              variant='text'
              style={{
                color: 'cyan-500',
                display: 'block',
                textTransform: 'none',
              }}
              onClick={() => handleButtonClick(item)}
              disabled={loading[item.name]}
            >
              {loading[item.name] && <CircularProgress size={24} />}
              {!loading[item.name] && item.label}
            </Button>
          ))}
        </div>
      </Container>

      <Dialog fullWidth open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Select Options</DialogTitle>
        <Box flex={1} p={5} display='flex' flexDirection='column' gap={3}>
          {/* Choose Profession */}
          <Box>
            <Typography fontFamily='Poppins' gutterBottom>
              Choose Profession*
            </Typography>
            <Select
              fullWidth
              displayEmpty
              sx={{ borderRadius: '8px' }}
              MenuProps={{
                disablePortal: true,
                PaperProps: { style: { zIndex: 1302 } },
              }}
              value={getClinic?.profession || ''}
              onChange={(e) =>
                setGetClinic((prev) => ({
                  ...prev,
                  profession: e.target.value,
                }))
              }
            >
              <MenuItem disabled value=''>
                <em style={{ color: '#8C8C8C' }}>Select</em>
              </MenuItem>
              {roles.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* Choose Type Slot */}
          <Box>
            <Typography fontFamily='Poppins' gutterBottom>
              Choose Type Slot*
            </Typography>
            <Select
              fullWidth
              displayEmpty
              sx={{ borderRadius: '8px' }}
              MenuProps={{
                disablePortal: true,
                PaperProps: { style: { zIndex: 1302 } },
              }}
              value={getClinic?.slot_type || ''}
              onChange={(e) =>
                setGetClinic((prev) => ({
                  ...prev,
                  slot_type: e.target.value,
                }))
              }
            >
              <MenuItem disabled value=''>
                <em style={{ color: '#8C8C8C' }}>Select</em>
              </MenuItem>
              {options.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box>
            <Typography fontFamily='Poppins' gutterBottom>
              Choose Range Date (optional)
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box display='flex' gap={8} mt={2}>
                {/* From Date Picker */}
                <DateField
                  variant='outlined'
                  label='From'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '6px',
                      width: '230px',
                    },
                  }}
                  // value={dayjs(getClinic.from)}
                  onChange={(newFrom) =>
                    setGetClinic((prev) => ({
                      ...prev,
                      from: dayjs(newFrom).format('YYYY-MM-DD'),
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{ borderRadius: '8px' }}
                    />
                  )}
                />
                {/* To Date Picker */}
                <DateField
                  variant='outlined'
                  label='To'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '6px',
                      width: '230px',
                    },
                  }}
                  // value={dayjs(getClinic.to)}
                  onChange={(newTo) =>
                    setGetClinic((prev) => ({
                      ...prev,
                      to: dayjs(newTo).format('YYYY-MM-DD'),
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{ borderRadius: '8px' }}
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </Box>

          {/* Choose Branch */}
          <Box>
            <Typography fontFamily='Poppins' gutterBottom>
              Choose Branch (optional)
            </Typography>
            <Select
              fullWidth
              // displayEmpty
              sx={{ borderRadius: '8px' }}
              MenuProps={{
                disablePortal: true,
                PaperProps: { style: { zIndex: 1302 } },
              }}
              value={getClinic?.clinic_id || ''}
              onChange={(e) =>
                setGetClinic((prev) => ({
                  ...prev,
                  clinic_id: e.target.value,
                }))
              }
            >
              <MenuItem disabled value=''>
                <em style={{ color: '#8C8C8C' }}>Select</em>
              </MenuItem>
              {Array.isArray(getClinic) &&
                getClinic.map(({ _id, clinicName }) => (
                  <MenuItem key={_id} value={_id}>
                    {clinicName}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Box>

        {/* Action Button */}
        <Box display='flex' justifyContent='end'>
          <Button
            onClick={() => setOpenModal(false)}
            variant='text'
            sx={{
              textTransform: 'none',
              color: 'purple',
            }}
          >
            Done
          </Button>
          &nbsp; &nbsp; &nbsp;
          <Button
            sx={{
              textTransform: 'none',
              paddingRight: 3,
            }}
            className='text-md rounded-md bg-cyan-600 px-16 font-bold text-white'
            variant='text'
            onClick={() =>
              handleDownload(
                '/admin/jobs/download/clinic/slots',
                'all-slot-list',
                {
                  params: {
                    profession: getClinic.profession,
                    doc_type: 'xlsx',
                    clinic_id: getClinic.clinic_id,
                    slot_type: getClinic.slot_type,
                    from: getClinic.from,
                    to: getClinic.to,
                  },
                },
              )()
            }
          >
            Download
          </Button>
        </Box>
        <br />
      </Dialog>
    </>
  );
}

export default DownloadListPage;
