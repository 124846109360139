import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import React from 'react';

export default function LicenseItemList({ items, handleGrant, handleRevoke }) {
  return (
    <div>
      <TableContainer
        className='rounded-xl'
        component={Paper}
        style={{
          width: '100%',
          paddingBottom: '12px',
          paddingLeft: '12px',
          paddingRight: '12px',
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell
                className='text-xs font-bold text-gray-400'
                style={{ width: '80px' }}
              >
                License Item
              </TableCell>
              <TableCell
                className='text-xs font-bold text-gray-400'
                style={{ padding: 0, width: '130px' }}
              >
                License Item Code
              </TableCell>
              <TableCell
                className='text-xs font-bold text-gray-400'
                style={{ padding: 0, width: '180px' }}
              >
                License Item Desc
              </TableCell>
              <TableCell
                className='text-xs font-bold text-gray-400'
                style={{ padding: 0, width: '100px' }}
              >
                Is Granted
              </TableCell>
              <TableCell
                className='text-xs font-bold text-gray-400'
                style={{ padding: 0, width: '150px' }}
              >
                Notes
              </TableCell>
              <TableCell
                className='text-xs font-bold text-gray-400'
                style={{ padding: 4, width: '160px' }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(items) && items.length > 0 ? (
              items.map((row) => (
                <TableRow
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={row.license_id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell
                    component='th'
                    scope='row'
                    className='border-white text-sm font-bold text-gray-500'
                  >
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                      }}
                    >
                      {row?.license_feature_item_group}
                    </span>
                  </TableCell>
                  <TableCell
                    className='border-white text-sm font-bold text-gray-500'
                    style={{ padding: 0 }}
                  >
                    {row?.license_feature_item_code}
                  </TableCell>
                  <TableCell
                    className='border-white text-sm font-bold text-gray-500'
                    style={{ padding: 0 }}
                  >
                    {row.license_feature_item_description}
                  </TableCell>
                  <TableCell
                    className='border-white text-sm font-bold text-gray-500'
                    style={{ padding: 0 }}
                  >
                    {row?.license_feature_item_is_granted ? 'true' : 'false'}
                  </TableCell>
                  <TableCell
                    className='border-white text-xs font-bold text-gray-500'
                    style={{ padding: 0 }}
                  >
                    {row.license_feature_item_notes || 'No notes added'}
                  </TableCell>
                  <TableCell
                    className='border-white text-sm font-bold text-gray-500'
                    style={{ padding: 0 }}
                  >
                    <div className='flex'>
                      <Button
                        variant='contained'
                        className='text-xs font-bold text-purple-600'
                        sx={{
                          textTransform: 'none',
                          backgroundColor: '#FAF9FC',
                          '&:hover': {
                            backgroundColor: 'white',
                          },
                        }}
                        onClick={() =>
                          handleGrant(
                            row?.license_id,
                            row?.license_feature_item_id,
                          )
                        }
                        disabled={row?.license_feature_item_is_granted}
                      >
                        Grant
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        variant='contained'
                        className='text-xs font-bold text-red-600'
                        sx={{
                          textTransform: 'none',
                          backgroundColor: '#FAF9FC',
                          '&:hover': {
                            backgroundColor: 'white',
                          },
                        }}
                        onClick={() =>
                          handleRevoke(
                            row?.license_id,
                            row?.license_feature_item_id,
                          )
                        }
                        disabled={!row?.license_feature_item_is_granted}
                      >
                        Revoke
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  className='border border-white'
                  colSpan={6}
                  align='center'
                >
                  <div className='font-poppins py-6 text-gray-500'>
                    No locum granted
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
