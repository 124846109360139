/* eslint-disable react/no-array-index-key */

/* eslint-disable no-unused-vars */
import '@fontsource/poppins';
import { Icon } from '@iconify/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// eslint-disable-next-line import/no-extraneous-dependencies
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useAuth } from '@src/contexts/authContext';
import { formatRole } from '@src/utils';
import axios from 'axios';

import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function ListingInformationForm() {
  const navigate = useNavigate();
  const state = useLocation();
  const { auth } = useAuth();
  const { jobsId } = useParams();
  const [jobs, setJobs] = useState();
  const format_date = { year: 'numeric', month: 'short', day: 'numeric' };

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/jobs/${jobsId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setJobs(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, jobsId]);

  const [getProfile, setGetProfile] = useState([]);
  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/jobs/bookedby?jobId=${jobsId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetProfile(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.idToken, jobsId]);

  const [exclusion, setExclusion] = useState();
  const [inclusion, setInclusion] = useState();

  function postInclusion(userId) {
    const formData = {
      user_id: userId,
      clinic_id: `${jobs?.clinic?._id}`,
    };
    axios
      .post(`${url}/admin/users/set-inclusion-by-clinic`, formData, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setInclusion(response.data);
        toast.success('The data updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  }

  function postExclusion(userId) {
    const formData = {
      user_id: userId,
      clinic_id: `${jobs?.clinic?._id}`,
    };
    axios
      .post(`${url}/admin/users/set-exclusion-by-clinic`, formData, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setExclusion(response.data);
        toast.success('The data updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  }

  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const handleEdit = () => {
    navigate(`/edit-past-slot/${jobsId}`);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const [selectedLocumId, setSelectedLocumId] = useState(null);
  const handleClick = (event, locumId) => {
    console.log('Clicked locum ID:', locumId);
    setAnchorEl(event.currentTarget);
    setSelectedLocumId(locumId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }} className='w-3/4'>
      <Box display='flex' className='justify-between'>
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-8 px-6 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button>

        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-8 px-14 font-bold text-cyan-600'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleEdit}
        >
          Edit slot
        </Button>
      </Box>
      <div className='grid grid-cols-2 px-8'>
        <div className='col-end-2'>
          <Box
            className='rounded-xl'
            sx={{
              height: '111px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-3 px-4 text-lg font-bold'>
              Listing code
            </div>
            <div className='flex justify-center from-cyan-500 to-cyan-600 text-2xl font-bold text-cyan-600 underline'>
              {jobs?.code}
            </div>
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
              fontFamily: 'Poppins',
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Work information
            </div>
            <div className='px-4'>
              <div className='col-span-full flex flex-wrap px-4 py-2'>
                <label className='text-md block leading-6 text-gray-600'>
                  Work date:
                </label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <div className='text-md px-9'>
                  {jobs?.date || 'No date added'}
                </div>
              </div>
              <div className='col-span-full flex flex-wrap px-4 py-1'>
                <label className='text-md block leading-6 text-gray-600'>
                  Work time:
                </label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <div className='text-md px-9'>
                  {jobs?.work_time_start} - {jobs?.work_time_finish}
                </div>
              </div>
              <div className='col-span-full flex flex-wrap px-4 py-2'>
                <label className='text-md block leading-6 text-gray-600'>
                  Listing type:
                </label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <div className='text-md px-7'>
                  <div>
                    {jobs?.listing_type
                      ? jobs.listing_type
                          .replace(/_/g, ' ')
                          .split(' ')
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1),
                          )
                          .join(' ')
                      : ''}
                  </div>
                </div>
              </div>
              <div className='col-span-full flex flex-wrap px-4 py-1'>
                <label className='text-md block leading-6 text-gray-600'>
                  Price job:
                </label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <div className='text-md px-12'>
                  <div>${jobs?.price} SGD</div>
                </div>
              </div>
              <div className='col-span-full flex flex-wrap px-4 py-2'>
                <label className='text-md block leading-6 text-gray-600'>
                  Job scope:
                </label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <div className='text-md px-9'>
                  <div>
                    {jobs?.scope.length > 0
                      ? jobs.scope.join(', ')
                      : 'No scope'}
                  </div>
                </div>
              </div>
              <div className='col-span-full flex flex-wrap px-4 py-1'>
                <label className='text-md block leading-6 text-gray-600'>
                  Break time:
                </label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <div className='text-md px-8'>
                  {jobs?.break[0]?.start} - {jobs?.break[0]?.finish}
                </div>
              </div>
            </div>
            <br />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: '230px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
              fontFamily: 'Poppins',
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Job status
            </div>
            <div className='px-4'>
              <div className='col-span-full flex flex-wrap px-4 py-2'>
                <label className='text-md block leading-6 text-gray-600'>
                  Status:
                </label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <div className='text-md px-16'>
                  <div>{jobs?.status || '-'}</div>
                </div>
              </div>
              <div className='col-span-full flex flex-wrap px-4 py-1'>
                <label className='text-md block leading-6 text-gray-600'>
                  Urgent status:
                </label>
                &nbsp;
                <div className='text-md px-12'>
                  <div>{jobs?.urgent_status || '-'}</div>
                </div>
              </div>
              <div className='col-span-full flex flex-wrap px-4 py-2'>
                <label className='text-md block leading-6 text-gray-600'>
                  Completed date:
                </label>

                <div className='text-md px-8'>
                  <div>
                    {jobs?.completed_date
                      ? new Date(jobs?.completed_date).toLocaleDateString(
                          'au-AU',
                          format_date,
                        )
                      : '-'}
                  </div>
                </div>
              </div>
              <div className='col-span-full flex flex-wrap px-4 py-1'>
                <label className='text-md block leading-6 text-gray-600'>
                  Completed status:
                </label>

                <div className='text-md px-5'>
                  {jobs?.completed ? 'true' : 'false'}
                </div>
              </div>
            </div>
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Preferences
            </div>
            <div className='px-4 sm:col-span-4'>
              <div className='text-gray-500'>
                {jobs?.preferences && jobs.preferences.length > 0 ? (
                  jobs.preferences.map((preference) => (
                    <div className='flex px-4'>
                      <Icon
                        icon='fluent:checkbox-checked-20-filled'
                        color='#4ed39c'
                        width='30'
                        height='30'
                      />
                      <div className='px-2 py-1'>{preference}</div>
                    </div>
                  ))
                ) : (
                  <div className='text-md px-6'>No preference added</div>
                )}
              </div>
            </div>
            <br />
            <br />
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Criterias
            </div>
            <div className='px-4 sm:col-span-4'>
              <div className='text-gray-500'>
                {jobs?.criterias && jobs.criterias.length > 0 ? (
                  jobs.criterias.map((criterias) => (
                    <div className='flex px-4'>
                      <Icon
                        icon='fluent:checkbox-checked-20-filled'
                        color='#4ed39c'
                        width='30'
                        height='30'
                      />
                      <div className='px-2 py-1'>{criterias}</div>
                    </div>
                  ))
                ) : (
                  <div className='text-md px-6'>No criteria added</div>
                )}
              </div>
            </div>
            <br />
            <br />
          </Box>
        </div>
        <div className='col-auto'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <Box>
              <div className='font-poppins py-4 px-4 text-lg font-bold'>
                Booked by
              </div>
            </Box>
            <div>
              <Grid className='flex flex-wrap px-4 text-gray-500'>
                {getProfile?.length > 0 ? (
                  getProfile.map((locum) => (
                    <React.Fragment key={locum._id}>
                      <List
                        sx={{
                          width: '100%',
                          maxWidth: 300,
                          bgcolor: 'background.paper',
                        }}
                        className='px-2'
                      >
                        <ListItem alignItems='flex-start'>
                          <ListItemAvatar>
                            <Avatar src={locum?.profile_pict} />
                          </ListItemAvatar>
                          <div>
                            <ListItemText
                              primary={
                                <span className='font-bold text-cyan-600 underline'>
                                  {locum?.full_name}
                                </span>
                              }
                              secondary={
                                <>
                                  <Typography
                                    sx={{ display: 'inline' }}
                                    component='span'
                                    variant='body2'
                                    color='text.secondary'
                                  >
                                    {locum?.role
                                      ? locum.role
                                          .replace(/_/g, ' ')
                                          .split(' ')
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1),
                                          )
                                          .join(' ')
                                      : ''}{' '}
                                    | {''}
                                    {locum?.role_id}
                                  </Typography>
                                  <div className='py-1.5 text-purple-500'>
                                    {locum?.booked_status
                                      ? locum.booked_status
                                          .replace(/_/g, ' ')
                                          .split(' ')
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1),
                                          )
                                          .join(' ')
                                      : ''}
                                  </div>
                                </>
                              }
                            />
                          </div>
                        </ListItem>
                      </List>
                      <IconButton
                        sx={{ paddingRight: 2 }}
                        aria-label='more'
                        id='long-button'
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup='true'
                        onClick={(event) => handleClick(event, locum._id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id='long-menu'
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        slotProps={{
                          paper: {
                            style: {
                              width: '20ch',
                            },
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => postInclusion(selectedLocumId)}
                        >
                          Inclusion
                        </MenuItem>
                        <MenuItem
                          onClick={() => postExclusion(selectedLocumId)}
                        >
                          Exclusion
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  ))
                ) : (
                  <div className='px-8'>No locum booked</div>
                )}
              </Grid>
              {/* <Button
                sx={{ textTransform: 'none', margin: 2, boxShadow: 'none' }}
                className='font-bold text-red-500'
                variant='text'
              >
                Remove
              </Button> */}
            </div>
            <br />
            <br />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Assigned to
            </div>
            <div className='px-4 text-gray-600'>
              {jobs?.length > 0 ? (
                jobs.map((job) => (
                  <Grid className='flex flex-wrap text-gray-500'>
                    <List
                      sx={{
                        width: '100%',
                        maxWidth: 300,
                        bgcolor: 'background.paper',
                      }}
                      className='px-2'
                    >
                      <ListItem alignItems='flex-start'>
                        <ListItemAvatar>
                          <Avatar src={job?.profile_pict} />
                        </ListItemAvatar>
                        <div className='py-2'>
                          <ListItemText
                            primary={
                              <span className='font-bold text-cyan-600 underline'>
                                {job?.assigned_to_users[0]?.full_name}
                              </span>
                            }
                          />
                        </div>
                      </ListItem>
                    </List>
                  </Grid>
                ))
              ) : (
                <div className='px-8'>No data</div>
              )}
            </div>
            <br />
            <br />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Cancelled by
            </div>
            <div className='px-4 text-gray-600'>
              {jobs?.cancellation_history?.length > 0 ? (
                <Grid className='flex flex-wrap text-gray-500'>
                  <List
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                    }}
                    className='px-2'
                  >
                    {jobs.cancellation_history.map((history, index) => (
                      <ListItem key={index} alignItems='flex-start'>
                        <ListItemAvatar>
                          <Avatar src={jobs?.profile_pict} />
                        </ListItemAvatar>
                        <div className='py-2'>
                          <ListItemText
                            primary={
                              <div className='space-y-2'>
                                <span className='mt-2 font-bold'>
                                  Cancelled user:{' '}
                                </span>
                                <span className='font-bold text-cyan-600'>
                                  {history?.user?.full_name || 'No Name'}
                                </span>
                                <br />
                                <span className='text-gray-500'>
                                  {formatRole(history?.user?.role) || 'No Role'}{' '}
                                  | {history?.user?.role_id || 'No Role ID'}
                                </span>
                                <br />
                                <div className='py-1' />
                                <span className='font-bold'>
                                  Cancelled by:{' '}
                                </span>
                                <span className='font-bold text-cyan-600'>
                                  {history?.by?.full_name || 'No Name'}
                                </span>
                                <br />
                                <span className='text-gray-500'>
                                  {formatRole(history?.by?.role) || 'No Role'} |{' '}
                                  {history?.by?.role_id || 'No Role ID'}
                                </span>
                                <br />
                                <span className='text-gray-500'>
                                  Date:{' '}
                                  {history?.date
                                    ? `${new Date(
                                        history.date,
                                      ).toLocaleDateString('en-US', {
                                        day: '2-digit',
                                        month: 'short',
                                        year: 'numeric',
                                      })}, ${new Date(
                                        history.date,
                                      ).toLocaleTimeString('en-US', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                      })}`
                                    : 'No Date'}
                                </span>
                              </div>
                            }
                          />
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ) : (
                <div className='px-8'>No data</div>
              )}
            </div>

            <br />
            <br />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Rejected user
            </div>
            <div className='px-4 text-gray-600'>
              {jobs?.length > 0 ? (
                jobs?.map((job) => (
                  <Grid className='flex flex-wrap text-gray-500'>
                    <List
                      sx={{
                        width: '100%',
                        maxWidth: 300,
                        bgcolor: 'background.paper',
                      }}
                      className='px-2'
                    >
                      <ListItem alignItems='flex-start'>
                        <ListItemAvatar>
                          <Avatar src={job?.profile_pict} />
                        </ListItemAvatar>
                        <div className='py-2'>
                          <ListItemText
                            primary={
                              <span className='font-bold text-cyan-600 underline'>
                                {job?.rejected_users?.full_name}
                              </span>
                            }
                          />
                        </div>
                      </ListItem>
                    </List>
                  </Grid>
                ))
              ) : (
                <div className='px-8'>No data</div>
              )}
            </div>
            <br />
            <br />
          </Box>
        </div>
      </div>
    </Box>
  );
}
