/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import { useState } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function UpdatePriceSlotNurse({ selected, getData }) {
  const { auth } = useAuth();

  const [modalUpdate, setModalUpdate] = useState(false);
  const showModalUpdate = () => {
    setModalUpdate(true);
  };

  const [modalDelete, setModalDelete] = useState(false);
  const showModalDelete = () => {
    setModalDelete(true);
  };

  const [updatePrice, setUpdatePrice] = useState({
    IDs: [],
    price: 0,
  });
  const [deleteSlot, setDeleteSlot] = useState({
    ids: [],
  });

  const handleUpdatePrice = () => {
    const flatIDs = Object.values(selected).flat();

    if (!updatePrice.price || flatIDs.length === 0) {
      toast.error('Please provide a price and select at least one item.', {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }

    const formData = {
      IDs: flatIDs,
      price: updatePrice.price,
    };

    axios
      .put(`${url}/admin/jobs/update-multiple-jobs`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Price updated successfully:', response);
        setUpdatePrice({ ...updatePrice, price: '' });
        getData();
        toast.success('Price updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.error('Error:', error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  const handleDeleteSlots = () => {
    const flatIDs = Object.values(selected).flat();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.idToken}`,
      },
      data: { ids: flatIDs },
    };

    axios
      .delete(`${url}/admin/v3/jobs`, config)
      .then((response) => {
        console.log('Slot deleted successfully:', response);
        setDeleteSlot((prev) => ({ ...prev, price: '' }));
        getData();
        toast.success('Slots deleted successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.error('Error:', error?.message);
        toast.error(error?.response?.data?.message || 'An error occurred', {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  const handleClose = () => {
    setModalUpdate(false);
    setModalDelete(false);
  };

  const [anchorElOption, setAnchorElOption] = React.useState(null);
  const openOption = Boolean(anchorElOption);
  const handleOption = {
    click: (ev) => setAnchorElOption(ev.currentTarget),
    close: () => setAnchorElOption(null),
    update: () => showModalUpdate(),
    delete: () => showModalDelete(),
  };

  return (
    <div>
      <Box className='px-2 py-2.5'>
        <Box display='flex' justifyContent='end'>
          <Button
            onClick={handleOption.click}
            className='h-[35px] w-[150px] rounded-xl border-none bg-[#9081D5] py-0 px-4 text-xs font-semibold normal-case text-white shadow hover:shadow-cyan-500'
            id='add-new-button'
            aria-controls={openOption ? 'add-new-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={openOption ? 'true' : undefined}
            disableElevation
          >
            Option
          </Button>
          <Menu
            id='add-new'
            MenuListProps={{
              'aria-labelledby': 'add-new-button',
            }}
            anchorEl={anchorElOption}
            open={openOption}
            onClose={handleOption.close}
          >
            {/* <MenuItem
              onClick={() => {
                handleOption.update();
                handleOption.close();
              }}
              disableRipple
            >
              Update Prices
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                handleOption.delete();
                handleOption.close();
              }}
              disableRipple
            >
              Delete Slots
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <Dialog
        maxWidth='lg'
        fullwidth
        open={modalUpdate}
        onClose={() => setModalUpdate(false)}
      >
        <DialogTitle>Update price(s)</DialogTitle>

        <DialogContent>
          <div className='flex justify-between'>
            <br />

            <Box flex={1} mr={1}>
              <Typography fontFamily='Poppins'>New Price</Typography>
              <Box display='flex' justifyContent='space-between'>
                <TextField
                  fullWidth
                  size='medium'
                  variant='outlined'
                  placeholder='only number'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '6px',
                    },
                    mt: 2,
                    width: '300px',
                  }}
                  onChange={(e) =>
                    setUpdatePrice({
                      ...updatePrice,
                      price: e.target.value,
                    })
                  }
                />
                <Typography paddingLeft={2} mt={4}>
                  SGD
                </Typography>
              </Box>
              <br />
              <br />
            </Box>

            <br />
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => setModalUpdate(false)}
            color='secondary'
          >
            Done
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            color='primary'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              handleUpdatePrice();
              handleClose();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='lg'
        fullwidth
        open={modalDelete}
        onClose={() => setModalDelete(false)}
      >
        <DialogTitle>Delete slot(s)</DialogTitle>

        <DialogContent>
          <div className='flex justify-between'>
            <br />

            <Box flex={1} mr={1}>
              <Typography fontFamily='Poppins'>
                Are you sure want to delete this slots?
              </Typography>
            </Box>

            <br />
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => setModalDelete(false)}
            color='error'
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            color='primary'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              handleDeleteSlots();
              handleClose();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
