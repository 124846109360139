import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/clinic/profile/settings/notification`;

export default function EditNotificationChannel({
  openModal,
  setOpenModal,
  fetchData,
}) {
  const { auth } = useAuth();
  const { notifId } = useParams();
  const [notif, setNotif] = useState();

  const handleClose = () => {
    setOpenModal(false);
  };

  React.useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        if (openModal) {
          const response = await axios.get(`${url}/${openModal}`, {
            headers: {
              Authorization: `Bearer ${auth.idToken}`,
            },
          });
          setNotif(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataAsync();
  }, [auth.idToken, openModal]);

  const handleEdit = () => {
    const formData = {
      channel_value: notif.channel_value,
      channel_type: notif.channel_type,
      channel_note: notif.channel_note,
    };

    axios
      .put(`${url}/${openModal}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Updated successfully:', response);
        setNotif({ ...notif });
        fetchData();
        toast.success('Updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogTitle>Edit Notification Channel</DialogTitle>

      <DialogContent>
        <div className='flex justify-between'>
          <br />

          <Box flex={1} mr={1}>
            <Typography fontFamily='Poppins'>Value*</Typography>
            <TextField
              fullWidth
              size='medium'
              variant='outlined'
              placeholder='Enter email/phone number'
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                  width: '400px',
                },
                mt: '5px',
              }}
              value={notif?.channel_value || ''}
              onChange={(e) =>
                setNotif({
                  ...notif,
                  channel_value: e.target.value,
                })
              }
            />
            <br />
            <br />
          </Box>

          <Box flex={1} mr={1}>
            <Typography fontFamily='Poppins'>Notes</Typography>
            <TextField
              fullWidth
              size='medium'
              variant='outlined'
              placeholder='Enter notes'
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                  width: '400px',
                },
                mt: '5px',
              }}
              value={notif?.channel_note || ''}
              onChange={(e) =>
                setNotif({
                  ...notif,
                  channel_note: e.target.value,
                })
              }
            />
            <br />
            <br />
          </Box>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModal(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => {
            handleEdit();
            handleClose();
          }}
          color='primary'
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
